<template>
  <div>
    <div class="banner">
      <el-carousel height="100%">
        <el-carousel-item v-for="item in banners" :key="item">
          <div class="img" :style="'background-image:url('+ item +')'"></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <div class="block">
        <h4 class="flex flex-align-center">
          <div class="circle"></div>我的需求
        </h4>
      </div>
      <div class="tabs flex flex-align-center;">
        <label
          v-for="(item, i) in tabs"
          :key="i"
          :class="'color-' + (i + 1)"
          @click="changeTab(i)"
        >{{ item }}</label>
        <div class="btm-line">
          <div
            :style="{ transform: 'translate(' + translateX + 'px)' }"
            :class="'color-' + (activeIndex + 1)"
          ></div>
        </div>
      </div>
      <div class="block block-info">
        <h5>基本信息</h5>
        <div class="mid">
          <div class="form-row flex">
            <div class="form-item flex flex-align-center">
              <label>订单号</label>
              <el-input v-model="baseInfo.order" placeholder="请填写需求标题"></el-input>
            </div>
            <div class="form-item flex flex-align-center">
              <label>规格</label>
              <el-input class="small" v-model="baseInfo.l" placeholder="长"></el-input>
              <el-input class="small" v-model="baseInfo.w" placeholder="宽"></el-input>
              <el-input class="small" v-model="baseInfo.h" placeholder="高"></el-input>
            </div>
          </div>
          <div class="form-row flex">
            <div class="form-item flex flex-align-center">
              <label>档次</label>
              <el-input v-model="baseInfo.level"></el-input>
            </div>
            <div class="form-item flex flex-align-center">
              <label>数量</label>
              <el-input v-model="baseInfo.amount"></el-input>
            </div>
          </div>
          <div class="form-row flex">
            <div class="form-item flex flex-align-center">
              <label>交货日期</label>
              <el-input v-model="baseInfo.time"></el-input>
            </div>
          </div>
        </div>
        <div class="btm">
          <el-button type="primary" size="small">提交</el-button>
        </div>
      </div>
      <div class="block-share block">
        <h4 class="flex flex-align-center">
          <div class="circle"></div>备料产能共享
        </h4>
        <div class="list flex">
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/share1.jpg" />
              <div class="info">
                <div>亚洲最大橡胶木备料</div>
                <div>厂房面积：38000平</div>
                <div>年产值：30000方</div>
              </div>
            </div>
            <div>赣州市南康区城发家具产业智能制造</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/share1.jpg" />
              <div class="info">
                <div>亚洲最大橡胶木备料</div>
                <div>厂房面积：38000平</div>
                <div>年产值：30000方</div>
              </div>
            </div>
            <div>赣州市南康区城发家具产业智能制造</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/share2.jpg" />
              <div class="info">
                <div>亚洲最大橡胶木备料</div>
                <div>厂房面积：38000平</div>
                <div>年产值：30000方</div>
              </div>
            </div>
            <div>赣州市南康区城发家具产业智能制造</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/share3.jpg" />
              <div class="info">
                <div>亚洲最大橡胶木备料</div>
                <div>厂房面积：38000平</div>
                <div>年产值：30000方</div>
              </div>
            </div>
            <div>赣州市南康区城发家具产业智能制造</div>
          </div>
        </div>
      </div>
      <div class="block-share block">
        <h4 class="flex flex-align-center">
          <div class="circle"></div>喷漆产能共享
        </h4>
        <div class="list flex">
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/penqi.png" />
              <div class="info">
                <div>厂房面积：38000平</div>
                <div>日产值：1200套</div>
              </div>
            </div>
            <div>汇明智能共享喷涂中心</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/penqi2.jpg" />
              <div class="info">
                <div>厂房面积：38000平</div>
                <div>日产值：1200套</div>
              </div>
            </div>
            <div>汇明智能共享喷涂中心</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/penqi3.jpg" />
              <div class="info">
                <div>厂房面积：38000平</div>
                <div>日产值：1200套</div>
              </div>
            </div>
            <div>汇明智能共享喷涂中心</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/penqi2.jpg" />
              <div class="info">
                <div>厂房面积：38000平</div>
                <div>日产值：1200套</div>
              </div>
            </div>
            <div>汇明智能共享喷涂中心</div>
          </div>
        </div>
      </div>
      <div class="block-share block">
        <h4 class="flex flex-align-center">
          <div class="circle"></div>外协产能共享
        </h4>
        <div class="list flex">
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/share.png" />
              <div class="info">
                <div>厂房面积：38000平</div>
                <div>日产值：1200套</div>
              </div>
            </div>
            <div>赣州市南康区城发家具产业智能制造</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/share.png" />
              <div class="info">
                <div>厂房面积：38000平</div>
                <div>日产值：1200套</div>
              </div>
            </div>
            <div>赣州市南康区城发家具产业智能制造</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/share.png" />
              <div class="info">
                <div>厂房面积：38000平</div>
                <div>日产值：1200套</div>
              </div>
            </div>
            <div>赣州市南康区城发家具产业智能制造</div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <img src="../../assets/images/home/share/share.png" />
              <div class="info">
                <div>厂房面积：38000平</div>
                <div>日产值：1200套</div>
              </div>
            </div>
            <div>赣州市南康区城发家具产业智能制造</div>
          </div>
        </div>
      </div>
      <!-- <div class="block block-1">
        <h4 class="flex flex-align-center">
          <div class="circle"></div>龙回共享备料数据
        </h4>
        <div class="chart-block">
          <div class="tit">
            <span>出库数据</span>
          </div>
          <div class="allmap" id="allmap"></div>
        </div>
        <div class="chart-block">
          <div class="tit">
            <span>加工中数据</span>
          </div>
          <div class="chart-main flex">
            <div class="chart-left">
              <div class="select-wrap">
                <el-date-picker type="date" v-model="date" placeholder="选择日期"></el-date-picker>
                <el-select v-model="kind" placeholder="选择种类">
                  <el-option key="1" label="胡桃木" value="1"></el-option>
                  <el-option key="2" label="橡胶木" value="2"></el-option>
                  <el-option key="3" label="白蜡木" value="3"></el-option>
                  <el-option key="4" label="黄玫瑰" value="4"></el-option>
                  <el-option key="5" label="樱桃木" value="5"></el-option>
                </el-select>
                <el-select v-model="spec" placeholder="选择规格">
                  <el-option key="1" label="1000mm*800mm*8mm" value="1"></el-option>
                  <el-option key="2" label="500mm*500mm*8mm" value="2"></el-option>
                  <el-option key="3" label="800mm*800mm*8mm" value="3"></el-option>
                  <el-option key="4" label="1000mm*700mm*5mm" value="4"></el-option>
                  <el-option key="5" label="500mm*500mm*10mm" value="5"></el-option>
                </el-select>
              </div>
              <div id="chart1" style="width:100%;height:300px;background:rgba(245,245,245,1);"></div>
            </div>
            <div class="chart-right">
              <div>
                <div style="text-align: left;line-height: 1">
                  <span class="tit">新增备料订单</span>
                </div>
                <div
                  style="width: 260px;height: 326px;background:rgba(245,245,245,1);margin-right: 95px;overflow:hidden;padding: 20px;box-sizing: border-box;"
                >
                  <div v-if="!scrolling">
                    <div
                      v-for="(order, index) in orders"
                      style="padding-bottom: 20px;"
                      :key="index"
                    >
                      <div
                        style="font-size:16px;font-weight:bold;padding-bottom: 14px;border-bottom: 1px solid #E6E6E6;"
                      >
                        <span style="color:rgba(246,111,106,1);margin-right:6px;">0{{ index + 1 }}</span>
                        <span style="color:rgba(77,77,77,1);">{{ order.name }}</span>
                      </div>
                      <div>
                        <div
                          style="display: flex;font-size:14px;font-weight:bold;color:rgba(128,128,128,1);margin-top: 10px;"
                          v-for="(row, index) in order.list"
                          :key="index"
                        >
                          <div
                            style="flex:1;text-align: left;color:#808080;font-weight:500;"
                          >{{ row.name }}</div>
                          <div
                            style="flex:1;text-align: center;color:#24B1EB;font-size:18px;"
                          >{{ row.count }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <vueSeamless
                    v-if="scrolling"
                    :data="listData"
                    :class-option="optionSingleHeightTime"
                    class="seamless-warp"
                  >
                    
                    <div
                      v-for="(order, index) in orders"
                      style="padding-bottom: 20px;"
                      :key="index"
                    >
                      <div
                        style="font-size:16px;font-weight:bold;padding-bottom: 14px;border-bottom: 1px solid #E6E6E6;"
                      >
                        <span style="color:rgba(246,111,106,1);margin-right:6px;">0{{ index + 1 }}</span>
                        <span style="color:rgba(77,77,77,1);">{{ order.name }}</span>
                      </div>
                      <div>
                        <div
                          style="display: flex;font-size:14px;font-weight:bold;color:rgba(128,128,128,1);margin-top: 10px;"
                          v-for="(row, index) in order.list"
                          :key="index"
                        >
                          <div
                            style="flex:1;text-align: left;color:#808080;font-weight:500;"
                          >{{ row.name }}</div>
                          <div
                            style="flex:1;text-align: center;color:#24B1EB;font-size:18px;"
                          >{{ row.count }}</div>
                        </div>
                      </div>
                    </div>
                  </vueSeamless>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { STATIC_URL_PRE } from '@/config';
// import echarts from "echarts";
// import "echarts-gl";
// import vueSeamless from "vue-seamless-scroll";
export default {
  components: {
    // vueSeamless
  },
  data() {
    return {
      banners: [
        STATIC_URL_PRE+"design/home%20page/banner-share.jpg",
        STATIC_URL_PRE+"design/home%20page/banner-share-2.jpg",
      ],
      baseInfo: {
        order: "",
        l: "",
        w: "",
        h: "",
        level: "",
        amount: "",
        time: ""
      },
      date: null,
      kind: null,
      spec: null,
      activeIndex: 0,
      translateX: 0,
      tabs: [
        "橡胶木备料",
        "奥古曼备料",
        "奥古曼卡斯拉备料",
        "榄仁木备料",
        "缅甸黄花梨备料",
        "黑胡桃木备料",
        "白腊木备料"
      ],
      orders: [
        {
          name: "赣州翔鹰实木家具",
          list: [
            { name: "床头柱", count: 2 },
            { name: "床头柱", count: 3 },
            { name: "床头柱", count: 6 }
          ]
        },
        {
          name: "庆朱家具有限公司",
          list: [
            { name: "床头柱", count: 7 },
            { name: "床头柱", count: 9 },
            { name: "床头柱", count: 8 }
          ]
        },
        {
          name: "江西华远宏伟有限公司",
          list: [
            { name: "床头柱", count: 9 },
            { name: "床头柱", count: 3 },
            { name: "床头柱", count: 6 }
          ]
        }
      ],
      scrolling: false,
      listData: [
        {
          title: "无缝滚动第一行无缝滚动第一行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第二行无缝滚动第二行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第三行无缝滚动第三行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第四行无缝滚动第四行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第五行无缝滚动第五行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第六行无缝滚动第六行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第七行无缝滚动第七行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第八行无缝滚动第八行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行无缝滚动第九行",
          date: "2017-12-16"
        }
      ]
    };
  },
  mounted() {
    setTimeout(() => {
      this.orders.unshift(this.orders[this.orders.length - 1]);
      setTimeout(() => {
        this.scrolling = true;
      }, 1000);
    }, 4000);
  },
  beforeDestroy() {
    this.mapChart.dispose();
    this.chart1.dispose();
  },
  computed: {
    optionSingleHeightTime() {
      return {
        step: 0.3,
        direction: 0
      };
    }
  },
  methods: {
    changeTab(i) {
      this.translateX += (i - this.activeIndex) * (144 + 24);
      this.activeIndex = i;
    },
  }
};
</script>
<style scoped lang="less">
.block {
  box-shadow: 0 2px 4px #dbe1f6;
  background: #fff;
  border: 1px solid #e6e6e6;
}
.banner {
  height: 420px;
  .el-carousel {
    height: 100%;
  }
  .img {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: auto 100%;
  }
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 134px;
  h4 {
    font-size: 24px;
    color: #292929;
    padding: 31px 26px;
    line-height: 1;
    margin: 0;
    .circle {
      width: 12px;
      height: 12px;
      border: 3px solid #24B1EB;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
}
.tabs {
  position: relative;
  padding: 30px 0;
  .btm-line {
    position: absolute;
    bottom: 0;
    div {
      width: 144px;
      height: 4px;
      background: linear-gradient(
        90deg,
        rgba(131, 98, 228, 1) 0%,
        rgba(80, 116, 238, 1) 100%
      );
      margin-left: 24px;
      transition: all 0.3s;
      &.color-1 {
        background: linear-gradient(
          90deg,
          rgba(255, 178, 150, 1) 0%,
          rgba(253, 136, 149, 1) 100%
        );
      }
      &.color-2 {
        background: linear-gradient(
          90deg,
          rgba(124, 191, 246, 1) 0%,
          rgba(39, 147, 230, 1) 100%
        );
      }
      &.color-3 {
        background: linear-gradient(
          270deg,
          rgba(80, 126, 207, 1) 0%,
          rgba(154, 161, 235, 1) 100%
        );
      }
      &.color-4 {
        background: linear-gradient(
          270deg,
          rgba(70, 184, 165, 1) 0%,
          rgba(128, 223, 174, 1) 100%
        );
      }
      &.color-5 {
        background: linear-gradient(
          90deg,
          rgba(191, 115, 213, 1) 0%,
          rgba(142, 57, 204, 1) 100%
        );
      }
      &.color-6 {
        background: linear-gradient(
          270deg,
          rgba(219, 162, 76, 1) 0%,
          rgba(250, 196, 96, 1) 100%,
          rgba(250, 196, 96, 1) 100%
        );
      }
      &.color-7 {
        background: linear-gradient(
          90deg,
          rgba(131, 98, 228, 1) 0%,
          rgba(80, 116, 238, 1) 100%
        );
      }
    }
  }
  label {
    cursor: pointer;
    width: 144px;
    height: 50px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 25px;
    margin-left: 24px;
    &.color-1 {
      background: linear-gradient(
        90deg,
        rgba(255, 178, 150, 1) 0%,
        rgba(253, 136, 149, 1) 100%
      );
      box-shadow: 0px 2px 10px 0px rgba(255, 193, 195, 1);
    }
    &.color-2 {
      background: linear-gradient(
        90deg,
        rgba(124, 191, 246, 1) 0%,
        rgba(39, 147, 230, 1) 100%
      );
      box-shadow: 0px 2px 10px 0px rgba(153, 211, 255, 1);
    }
    &.color-3 {
      background: linear-gradient(
        270deg,
        rgba(80, 126, 207, 1) 0%,
        rgba(154, 161, 235, 1) 100%
      );
      box-shadow: 0px 2px 10px 0px rgba(199, 219, 255, 1);
    }
    &.color-4 {
      background: linear-gradient(
        270deg,
        rgba(70, 184, 165, 1) 0%,
        rgba(128, 223, 174, 1) 100%
      );
      box-shadow: 0px 2px 10px 0px rgba(189, 247, 242, 1);
    }
    &.color-5 {
      background: linear-gradient(
        90deg,
        rgba(191, 115, 213, 1) 0%,
        rgba(142, 57, 204, 1) 100%
      );
      box-shadow: 0px 2px 10px 0px rgba(223, 178, 255, 1);
    }
    &.color-6 {
      background: linear-gradient(
        270deg,
        rgba(219, 162, 76, 1) 0%,
        rgba(250, 196, 96, 1) 100%,
        rgba(250, 196, 96, 1) 100%
      );
      box-shadow: 0px 2px 10px 0px rgba(255, 228, 186, 1);
    }
    &.color-7 {
      background: linear-gradient(
        90deg,
        rgba(131, 98, 228, 1) 0%,
        rgba(80, 116, 238, 1) 100%
      );
      box-shadow: 0px 2px 10px 0px rgba(190, 205, 255, 1);
    }
  }
}
.block-info {
  h5 {
    height: 70px;
    line-height: 70px;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 30px;
    font-size: 18px;
    color: #3b3b3b;
    margin: 0;
  }
  .mid {
    padding: 20px 35px;
    .form-row {
      .form-item {
        margin-bottom: 20px;
        &:first-child {
          margin-right: 65px;
        }
        label {
          width: 56px;
          margin-right: 21px;
          color: #4d4d4d;
          font-weight: 500;
          text-align: right;
        }
        .el-input {
          width: 240px;
          &.small {
            width: 120px;
            margin-right: 20px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .btm {
    padding: 20px 30px;
    border-top: 1px solid #e6e6e6;
  }
}
.block-share {
  margin-top: 10px;
  &:first-child {
    margin-top: 26px;
  }
  .list {
    .item {
      width: 264px;
      margin-left: 28px;
      padding-bottom: 30px;
      cursor: pointer;
      > div {
        color: #292929;
        font-weight: bold;
        font-size: 16px;
      }
      .img-wrap {
        width: 264px;
        height: 264px;
        margin-bottom: 14px;
        position: relative;
        &:hover {
          .info {
            display: flex;
          }
        }
        .info {
          width: 100%;
          height: 100%;
          font-size: 14px;
          color: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 20px;
          background: rgba(41, 41, 41, 0.2);
          box-shadow: 0px 2px 4px 0px rgba(204, 204, 204, 1);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          display: none;
          div {
            margin-top: 10px;
          }
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.block-1 {
  margin-top: 26px;
  background-color: #fafafa;
  .chart-block {
    width: 1140px;
    margin: 0 auto;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    > .tit {
      height: 63px;
      line-height: 63px;
      span {
        margin-left: 20px;
        display: inline-block;
        line-height: 1;
        padding-left: 10px;
        border-left: 4px solid #24B1EB;
        color: #292929;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .allmap {
      height: 550px;
      width: 100%;
    }
    .chart-main {
      height: 408px;
      border-top: 1px solid #ccc;
      .chart-left {
        height: 100%;
        width: 840px;
        border-right: 1px solid #ccc;
        padding: 30px;
        .select-wrap {
          margin-bottom: 20px;
          > div {
            width: 220px;
            margin-left: 30px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
      .chart-right {
        padding: 20px;
        width: 298px;
        .tit {
          font-size: 16px;
          color: #292929;
          font-weight: bold;
          margin-bottom: 20px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
